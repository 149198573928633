import './AboutUs.css';

function AboutUs() {
  return (
    <section id="aboutUs" className="about-us">
      <div className="about-container">
        <div className="about-content">
          <div className="about-title">Про нас</div>
          <div className="about-text">
            Я Володимир, юрист з понад 10-річним досвідом у сфері адміністративного права. Моя мета
            — зробити юриспруденцію зрозумілою та доступною для всіх. Я спеціалізуюсь на створенні
            нормативних актів і судовому представництві, маю досвід у роботі з правами та
            обов’язками органів влади та громадян. Сучасні зміни в законодавстві щодо військового
            обліку, мобілізації та служби вносять нові реалії в наше життя. Я допомагаю
            орієнтуватися в цих змінах, забезпечуючи чітке розуміння ваших прав та обов’язків у цих
            питаннях. Мій принцип життя: “Виконуючи свій обов’язок, я очікую того ж від інших”. У
            цей час важливо зберігати єдність і діяти з майстерністю та порядком. Я тут, щоб
            допомогти вам орієнтуватися в складностях правової системи і захистити ваші права.
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
